import React, { Component } from 'react';
import { array, bool, func, number, object, shape, string } from 'prop-types';
import classNames from 'classnames';
import omit from 'lodash/omit';
import config from '../../config';
import routeConfiguration from '../../routeConfiguration';
import { FormattedMessage } from '../../util/reactIntl';
import { createResourceLocatorString } from '../../util/routes';
import { isAnyFilterActive } from '../../util/search';
import { propTypes } from '../../util/types';
import {
  SearchResultsPanel,
  SearchFiltersMobile,
  SearchFiltersPrimary,
  SearchFiltersSecondary,
  SortBy,
  PriceFilter,
  SelectMultipleFilter,
} from '../../components';
import lottieJson from '../../components/siuhuu-lottie.json';

import FilterComponent from './FilterComponent';
import { validFilterParams } from './SearchPage.helpers';
import { ReactComponent as ArrowUp } from '../../assets/ArrowUp.svg';
import { ReactComponent as ArrowDown } from '../../assets/ArrowDown.svg';
import { ReactComponent as NoListSvg } from '../../assets/noListing.svg';
import { ReactComponent as CloseBtn } from '../../assets/CrossCircle.svg';
import { ReactComponent as FilterIcon } from '../../assets/Filtericon.svg';

import { categoriesWithSubCategories } from '../../marketplace-custom-config';
import css from './SearchPage.module.css';

import MediaQuery from 'react-responsive';
import Lottie from 'react-lottie-player';
import { parse } from '../../util/urlHelpers';
// import {
//   Accordion,
//   AccordionItem,
//   AccordionItemButton,
//   AccordionItemHeading,
//   AccordionItemPanel,
// } from 'react-accessible-accordion';

// Primary filters have their content in dropdown-popup.
// With this offset we move the dropdown to the left a few pixels on desktop layout.
const FILTER_DROPDOWN_OFFSET = -14;
const MAX_MOBILE_SCREEN_WIDTH = 992;

const cleanSearchFromConflictingParams = (
  searchParams,
  sortConfig,
  filterConfig
) => {
  // Single out filters that should disable SortBy when an active
  // keyword search sorts the listings according to relevance.
  // In those cases, sort parameter should be removed.
  const sortingFiltersActive = isAnyFilterActive(
    sortConfig.conflictingFilters,
    searchParams,
    filterConfig
  );
  return sortingFiltersActive
    ? { ...searchParams, [sortConfig.queryParamName]: null }
    : searchParams;
};

/**
 * MainPanel contains search results and filters.
 * There are 3 presentational container-components that show filters:
 * SearchfiltersMobile, SearchFiltersPrimary, and SearchFiltersSecondary.
 * The last 2 are for desktop layout.
 */

const SelectedFiltersContainer = props => {
  const {
    selectedKeyword,
    selectedLocation,
    removeSelectedLocation,
    removeSelectedKeyword,
    productCategoryOptions,
    selectedCategory,
    removeSelectedCategory,
    productSubCategoryOptions,
    selectedSubCategory,
    removeSelectedSubCategory,
    clothingSizeOptions,
    selectedClothingSize,
    removeClothingSize,
    dinningFilterOptions,
    selectedDinningOptions,
    removeDinningOptions,
    bedroomNumberOptions,
    selectedBedroomNumbers,
    removeBedroomNumbers,
    bathroomNumberOptions,
    selectedBathroomNumbers,
    removeBathroomNumbers,
    accommodationFeaturesFilterOptions,
    selectedAccommodationFeatures,
    removeAccommodationFeatures,
    handleResetAll,
    currentQueryParams,
  } = props;

  const showClearAll = Object.values(currentQueryParams).length;
  //category
  const selectedCategorylabel = selectedCategory
    ? productCategoryOptions.find(f => f.value === selectedCategory).label
    : null;
  //sub category
  const selectedSubCategorylabel = selectedSubCategory
    ? productSubCategoryOptions.find(f => f.value === selectedSubCategory).label
    : null;

  // console.log(selectedSubCategory, productSubCategoryOptions, 458);
  //Clothing Size
  const clothingSizeArray = selectedClothingSize
    .toString()
    .replace('has_any:', '')
    .split(',');

  const selectedclothingSizelabelArray = selectedClothingSize
    ? clothingSizeOptions.filter(m => clothingSizeArray.includes(m.key))
    : // .map(m => m.label)
      null;

  //Dinning Option

  const dinningFilterArray = selectedDinningOptions
    .toString()
    .replace('has_any:', '')
    .split(',');

  const selectedDinningOptionslabelArray = selectedDinningOptions
    ? dinningFilterOptions.filter(m => dinningFilterArray.includes(m.key))
    : null;

  //bedroom number
  const bedroomNumberFilterArray = selectedBedroomNumbers
    .toString()
    .replace('has_any:', '')
    .split(',');

  const selectedBedroomNumberslabelArray = selectedBedroomNumbers
    ? bedroomNumberOptions.filter(m => bedroomNumberFilterArray.includes(m.key))
    : null;

  //bathrom number

  const bathroomNumberFilterArray = selectedBathroomNumbers
    .toString()
    .replace('has_any:', '')
    .split(',');

  const selectedBathroomNumberslabelArray = selectedBathroomNumbers
    ? bathroomNumberOptions.filter(m =>
        bathroomNumberFilterArray.includes(m.key)
      )
    : null;

  //accomodation feature
  const accommodationFeaturesFilterArray = selectedAccommodationFeatures
    .toString()
    .replace('has_any:', '')
    .split(',');

  const accommodationFeaturesFilterlabelArray = selectedBathroomNumbers
    ? accommodationFeaturesFilterOptions.filter(m =>
        accommodationFeaturesFilterArray.includes(m.key)
      )
    : null;

  return (
    <div className={css.filterDiv}>
      {/* <MediaQuery maxWidth={767}>
        {showClearAll > 1 ? (
          <button
            type="button"
            className={css.resetAllButton}
            onClick={handleResetAll}
          >
            Clear All
          </button>
        ) : null}
      </MediaQuery> */}
      {selectedKeyword && selectedKeyword !== '' ? (
        <div className={css.filterBtns} key={'selectedKeyword'}>
          Search: <span>{selectedKeyword}</span>
          <CloseBtn
            className={css.closeFilter}
            onClick={() => {
              removeSelectedKeyword();
            }}
          />
        </div>
      ) : null}
      {selectedLocation ? (
        <div className={css.filterBtns} key={'selectedLocation'}>
          <span>{selectedLocation}</span>
          <CloseBtn
            className={css.closeFilter}
            onClick={() => {
              removeSelectedLocation();
            }}
          />
        </div>
      ) : null}
      {selectedCategory ? (
        <div className={css.filterBtns} key={'selectedCategory'}>
          {selectedCategorylabel}
          {/* {selectedCategory} */}
          <CloseBtn
            className={css.closeFilter}
            onClick={() => {
              removeSelectedCategory();
            }}
          />
        </div>
      ) : null}
      {/* {console.log(252, selectedSubCategory)} */}

      {selectedSubCategory ? (
        <div className={css.filterBtns} key={'selectedSubCategory'}>
          {selectedSubCategorylabel}
          <CloseBtn
            className={css.closeFilter}
            onClick={() => {
              removeSelectedSubCategory();
            }}
          />
        </div>
      ) : null}
      {selectedClothingSize &&
        selectedClothingSize.length > 0 &&
        selectedclothingSizelabelArray.map((i, index) => {
          return (
            <div className={css.filterBtns} key={`${i.value} ${index}`}>
              {/* {i} */}
              {i.label}
              {/* {selectedclothingSizelabelArray.length - 1 === index ? '' : ''} */}
              <CloseBtn
                className={css.closeFilter}
                onClick={() => {
                  removeClothingSize(i);
                }}
              />
            </div>
          );
        })}
      {selectedDinningOptions &&
        selectedDinningOptions.length > 0 &&
        selectedDinningOptionslabelArray.map((i, index) => {
          return (
            <div className={css.filterBtns} key={`${i.value} ${index}`}>
              {i.label}
              <CloseBtn
                className={css.closeFilter}
                onClick={() => {
                  removeDinningOptions(i);
                }}
              />
            </div>
          );
        })}
      {selectedBedroomNumbers &&
        selectedBedroomNumbers.length > 0 &&
        selectedBedroomNumberslabelArray.map((i, index) => {
          return (
            <div className={css.filterBtns} key={`${i.value} ${index}`}>
              Bedroom Numbers: <span>{i.label}</span>
              <CloseBtn
                className={css.closeFilter}
                onClick={() => {
                  removeBedroomNumbers(i);
                }}
              />
            </div>
          );
        })}
      {selectedBathroomNumbers &&
        selectedBathroomNumbers.length > 0 &&
        selectedBathroomNumberslabelArray.map((i, index) => (
          <div className={css.filterBtns} key={`${i.value} ${index}`}>
            Bathroom Numbers: <span>{i.label}</span>
            <CloseBtn
              className={css.closeFilter}
              onClick={() => {
                removeBathroomNumbers(i);
              }}
            />
          </div>
        ))}
      {selectedAccommodationFeatures &&
        selectedAccommodationFeatures.length > 0 &&
        accommodationFeaturesFilterlabelArray.map((i, index) => (
          <div className={css.filterBtns} key={`${i.value} ${index}`}>
            {i.label}

            <CloseBtn
              className={css.closeFilter}
              onClick={() => {
                removeAccommodationFeatures(i);
              }}
            />
          </div>
        ))}

      {showClearAll > 1 ? (
        <button
          type="button"
          className={css.resetAllButton}
          onClick={handleResetAll}
        >
          Clear All
        </button>
      ) : null}
    </div>
  );
};
class MainPanel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isSecondaryFiltersOpen: false,
      currentQueryParams: props.urlQueryParams,
      categoriesOpen: true,
      subCategoriesOpen: '',
      // subCategoriesOpenMobile: '',
      priceOpen: true,
      clothingSizeOpen: true,
      bedroomFilterOpen: true,
      bathroomFilterOpen: true,
      dinningFilterOpen: true,
      accommodationFilterOpen: true,
      categoriesMobileOpen: false,
      filterMobileOpen: false,
      accordionFlag: true,
      accordionValueOld: null,
      accordionValueNew: null,
      // mobileTopPosition: 0,
    };

    // this.handleResize = this.handleResize.bind(this);

    this.applyFilters = this.applyFilters.bind(this);
    this.cancelFilters = this.cancelFilters.bind(this);
    this.resetAll = this.resetAll.bind(this);

    this.initialValues = this.initialValues.bind(this);
    this.getHandleChangedValueFn = this.getHandleChangedValueFn.bind(this);

    // SortBy
    this.handleSortBy = this.handleSortBy.bind(this);
  }

  // componentDidMount() {
  //   if (typeof window !== 'undefined') {
  //     window.addEventListener('resize', this.handleResize);
  //     this.handleResize();
  //   }
  // }

  // componentWillUnmount() {
  //   if (typeof window !== 'undefined') {
  //     window.removeEventListener('resize', this.handleResize);
  //   }
  // }

  // handleResize() {
  //   if (typeof window !== 'undefined') {
  //     const viewportHeight = window.innerHeight;
  //     this.setState({ mobileTopPosition: `${viewportHeight * 0.23}px` }); // 11% of the viewport height
  //   }
  // }

  // Apply the filters by redirecting to SearchPage with new filters.

  applyFilters() {
    const { history, urlQueryParams, sortConfig, filterConfig } = this.props;

    const searchParams = {
      ...urlQueryParams,
      ...this.state.currentQueryParams,
    };
    const search = cleanSearchFromConflictingParams(
      searchParams,
      sortConfig,
      filterConfig
    );

    history.push(
      createResourceLocatorString(
        'SearchPage',
        routeConfiguration(),
        {},
        search
      )
    );
  }

  // Close the filters by clicking cancel, revert to the initial params
  cancelFilters() {
    this.setState({ currentQueryParams: {} });
  }

  // Reset all filter query parameters
  resetAll(e) {
    const { urlQueryParams, history, filterConfig } = this.props;
    const filterQueryParamNames = filterConfig.map(f => f.queryParamNames);

    // Reset state
    this.setState({
      currentQueryParams: {},
      accordionFlag: !this.state.accordionFlag,
      accordionValueOld: null,
      accordionValueNew: null,
    });

    // Reset routing params
    const queryParams = omit(urlQueryParams, filterQueryParamNames);
    history.push(
      createResourceLocatorString(
        'SearchPage',
        routeConfiguration(),
        {},
        // queryParams
        {}
      )
    );
  }

  initialValues(queryParamNames) {
    // Query parameters that are visible in the URL
    const urlQueryParams = this.props.urlQueryParams;
    // Query parameters that are in state (user might have not yet clicked "Apply")
    const currentQueryParams = this.state.currentQueryParams;

    // Get initial value for a given parameter from state if its there.
    const getInitialValue = paramName => {
      const currentQueryParam = currentQueryParams[paramName];
      const hasQueryParamInState = typeof currentQueryParam !== 'undefined';
      return hasQueryParamInState
        ? currentQueryParam
        : urlQueryParams[paramName];
    };

    // Return all the initial values related to given queryParamNames
    // InitialValues for "amenities" filter could be
    // { amenities: "has_any:towel,jacuzzi" }
    const isArray = Array.isArray(queryParamNames);
    return isArray
      ? queryParamNames.reduce((acc, paramName) => {
          return { ...acc, [paramName]: getInitialValue(paramName) };
        }, {})
      : {};
  }

  getHandleChangedValueFn(useHistoryPush) {
    const { urlQueryParams, history, sortConfig, filterConfig } = this.props;

    return updatedURLParams => {
      const updater = prevState => {
        const { address, bounds } = urlQueryParams;
        const mergedQueryParams = {
          ...urlQueryParams,
          ...prevState.currentQueryParams,
        };

        // Address and bounds are handled outside of MainPanel.
        // I.e. TopbarSearchForm && search by moving the map.
        // We should always trust urlQueryParams with those.
        return {
          currentQueryParams: {
            ...mergedQueryParams,
            ...updatedURLParams,
            address,
            bounds,
          },
        };
      };

      const callback = () => {
        if (useHistoryPush) {
          const searchParams = this.state.currentQueryParams;
          const search = cleanSearchFromConflictingParams(
            searchParams,
            sortConfig,
            filterConfig
          );
          history.push(
            createResourceLocatorString(
              'SearchPage',
              routeConfiguration(),
              {},
              search
            )
          );
        }
      };

      this.setState(updater, callback);
    };
  }

  handleSortBy(urlParam, values) {
    const { history, urlQueryParams } = this.props;
    const queryParams = values
      ? { ...urlQueryParams, [urlParam]: values }
      : omit(urlQueryParams, urlParam);

    history.push(
      createResourceLocatorString(
        'SearchPage',
        routeConfiguration(),
        {},
        queryParams
      )
    );
  }

  render() {
    const {
      className,
      rootClassName,
      urlQueryParams,
      listings,
      searchInProgress,
      searchListingsError,
      searchParamsAreInSync,
      onActivateListing,
      onManageDisableScrolling,
      onOpenModal,
      onCloseModal,
      onMapIconClick,
      pagination,
      searchParamsForPagination,
      showAsModalMaxWidth,
      filterConfig,
      sortConfig,
      currentUser,
      viewport,
      history,
    } = this.props;

    const primaryFilters = filterConfig.filter(f => f.group === 'primary');

    const clothingSizeFilter = filterConfig.find(f => f.id === 'clothingSize');
    const dinningOptionsFilter = filterConfig.find(
      f => f.id === 'dinningOptions'
    );
    const bathroomNumbersFilter = filterConfig.find(
      f => f.id === 'bathroomNumbers'
    );
    const bedroomNumbersFilter = filterConfig.find(
      f => f.id === 'bedroomNumbers'
    );
    const accommodationFeaturesFilter = filterConfig.find(
      f => f.id === 'accommodationFeatures'
    );
    const secondaryFilters = filterConfig.filter(f => f.group !== 'primary');
    const hasSecondaryFilters = !!(
      secondaryFilters && secondaryFilters.length > 0
    );

    // Selected aka active filters
    const selectedFilters = validFilterParams(urlQueryParams, filterConfig);
    const selectedFiltersCount = Object.keys(selectedFilters).length;

    // Selected aka active secondary filters
    const selectedSecondaryFilters = hasSecondaryFilters
      ? validFilterParams(urlQueryParams, secondaryFilters)
      : {};
    const selectedSecondaryFiltersCount = Object.keys(selectedSecondaryFilters)
      .length;

    const isSecondaryFiltersOpen =
      !!hasSecondaryFilters && this.state.isSecondaryFiltersOpen;
    const propsForSecondaryFiltersToggle = hasSecondaryFilters
      ? {
          isSecondaryFiltersOpen: this.state.isSecondaryFiltersOpen,
          toggleSecondaryFiltersOpen: isOpen => {
            this.setState({ isSecondaryFiltersOpen: isOpen });
          },
          selectedSecondaryFiltersCount,
        }
      : {};

    // With time-based availability filtering, pagination is NOT
    // supported. In these cases we get the pagination support info in
    // the response meta object, and we can use the count of listings
    // as the result count.
    //
    // See: https://www.sharetribe.com/api-reference/marketplace.html#availability-filtering
    const hasPaginationInfo = !!pagination && !pagination.paginationUnsupported;
    const listingsLength = listings ? listings.length : 0;
    const totalItems =
      searchParamsAreInSync && hasPaginationInfo
        ? pagination.totalItems
        : listingsLength;

    const listingsAreLoaded = !searchInProgress && searchParamsAreInSync;

    const sortBy = mode => {
      const conflictingFilterActive = isAnyFilterActive(
        sortConfig.conflictingFilters,
        urlQueryParams,
        filterConfig
      );

      const mobileClassesMaybe =
        mode === 'mobile'
          ? {
              rootClassName: css.sortBy,
              menuLabelRootClassName: css.sortByMenuLabel,
            }
          : {};

      return sortConfig.active ? (
        <SortBy
          {...mobileClassesMaybe}
          sort={urlQueryParams[sortConfig.queryParamName]}
          isConflictingFilterActive={!!conflictingFilterActive}
          onSelect={this.handleSortBy}
          showAsPopup
          contentPlacementOffset={FILTER_DROPDOWN_OFFSET}
        />
      ) : null;
    };

    const classes = classNames(
      rootClassName || css.searchResultContainer,
      className
    );
    const searchedparam = parse(history?.location?.search);
    //keyword
    // const selectedKeyword = this.state.currentQueryParams.keywords ?? '';
    const selectedKeyword = searchedparam?.keywords ?? '';
    const removeSelectedKeyword = () => {
      const { history } = this.props;
      this.setState(
        state => {
          return {
            currentQueryParams: {
              ...state.currentQueryParams,
              ...parse(history?.location?.search),
              keywords: undefined,
            },
          };
        },
        () => {
          history.push(
            createResourceLocatorString(
              'SearchPage',
              routeConfiguration(),
              {},
              this.state.currentQueryParams
            )
          );
        }
      );
    };
    //location
    // const selectedLocation = this.state.currentQueryParams.address ?? '';
    const selectedLocation = searchedparam?.address ?? '';

    const removeSelectedLocation = () => {
      const { history } = this.props;
      this.setState(
        state => {
          return {
            currentQueryParams: {
              ...state.currentQueryParams,
              ...parse(history?.location?.search),
              address: undefined,
              bounds: undefined,
              origin: undefined,
            },
          };
        },
        () => {
          history.push(
            createResourceLocatorString(
              'SearchPage',
              routeConfiguration(),
              {},
              this.state.currentQueryParams
            )
          );
        }
      );
    };
    //category remove function
    const productCategoryOptions = filterConfig.find(
      f => f.id === 'productCategory'
    ).config.options; 
    const selectedCategory =
      // this.state.currentQueryParams.pub_productCategory ?? '';
      searchedparam?.pub_productCategory ?? '';

    const removeSelectedCategory = () => {
      const { history } = this.props;
      this.setState(
        state => {
          return {
            currentQueryParams: {
              ...state.currentQueryParams,
              pub_productCategory: undefined,
              pub_productSubCategory: undefined,
            },
          };
        },
        () => {
          history.push(
            createResourceLocatorString(
              'SearchPage',
              routeConfiguration(),
              {},
              this.state.currentQueryParams
            )
          );
        }
      );
    };

    //Sub-category remove function

    const productSubCategoryOptions = filterConfig
      .filter(f => f.queryParamNames?.includes('pub_productSubCategory'))
      .map(f => f.config.options)
      .reduce((accumulator, obj) => [...accumulator, ...obj], []);

    // const selectedSubCategory = this.state.currentQueryParams
    //   .pub_productSubCategory
    //   ? this.state.currentQueryParams.pub_productSubCategory
    //   : '';
    const selectedSubCategory = searchedparam?.pub_productSubCategory
      ? searchedparam?.pub_productSubCategory
      : '';

    const removeSelectedSubCategory = () => {
      const { history } = this.props;
      this.setState(
        state => {
          return {
            currentQueryParams: {
              ...state.currentQueryParams,
              pub_productSubCategory: undefined,
            },
          };
        },
        () => {
          history.push(
            createResourceLocatorString(
              'SearchPage',
              routeConfiguration(),
              {},
              this.state.currentQueryParams
            )
          );
        }
      );
    };

    //Clothing Size remove function
    const clothingSizeOptions = clothingSizeFilter.config.options;

    const selectedClothingSize =
      this.state.currentQueryParams.pub_clothingSize?.split(',') ?? [];

    const removeClothingSize = i => {
      const { history } = this.props;

      this.setState(
        state => {
          return {
            currentQueryParams: {
              ...state.currentQueryParams,
              pub_clothingSize:
                state.currentQueryParams.pub_clothingSize?.split(',').length > 1
                  ? state.currentQueryParams.pub_clothingSize
                      ?.replace('has_any:', '')
                      .split(',')
                      .filter(f => f !== i.value)
                      .join(',')
                  : undefined,
            },
          };
        },
        () => {
          history.push(
            createResourceLocatorString(
              'SearchPage',
              routeConfiguration(),
              {},
              this.state.currentQueryParams
            )
          );
        }
      );
    };
    // Dinning Size
    const dinningFilterOptions = dinningOptionsFilter.config.options;

    const selectedDinningOptions =
      this.state.currentQueryParams.pub_dinningOptions?.split(',') ?? [];

    const removeDinningOptions = i => {
      const { history } = this.props;

      this.setState(
        state => {
          return {
            currentQueryParams: {
              ...state.currentQueryParams,
              pub_dinningOptions:
                state.currentQueryParams.pub_dinningOptions?.split(',').length >
                1
                  ? state.currentQueryParams.pub_dinningOptions
                      ?.replace('has_any:', '')
                      .split(',')
                      .filter(f => f !== i.value)
                      .join(',')
                  : undefined,
            },
          };
        },
        () => {
          history.push(
            createResourceLocatorString(
              'SearchPage',
              routeConfiguration(),
              {},
              this.state.currentQueryParams
            )
          );
        }
      );
    };
    //bedroom
    const bedroomNumberOptions = bedroomNumbersFilter.config.options;

    const selectedBedroomNumbers =
      this.state.currentQueryParams.pub_bedroomCount?.split(',') ?? [];

    const removeBedroomNumbers = i => {
      const { history } = this.props;
      this.setState(
        state => {
          return {
            currentQueryParams: {
              ...state.currentQueryParams,
              pub_bedroomCount:
                state.currentQueryParams.pub_bedroomCount?.split(',').length > 1
                  ? state.currentQueryParams.pub_bedroomCount
                      ?.replace('has_any:', '')
                      .split(',')
                      .filter(f => f !== i.value)
                      ?.join(',')
                  : undefined,
            },
          };
        },
        () => {
          history.push(
            createResourceLocatorString(
              'SearchPage',
              routeConfiguration(),
              {},
              this.state.currentQueryParams
            )
          );
        }
      );
    };
    //bathroom
    const bathroomNumberOptions = bathroomNumbersFilter.config.options;

    const selectedBathroomNumbers =
      this.state.currentQueryParams.pub_bathroomCount?.split(',') ?? [];

    const removeBathroomNumbers = i => {
      const { history } = this.props;
      this.setState(
        state => {
          return {
            currentQueryParams: {
              ...state.currentQueryParams,
              pub_bathroomCount:
                state.currentQueryParams.pub_bathroomCount?.split(',').length >
                1
                  ? state.currentQueryParams.pub_bathroomCount
                      ?.replace('has_any:', '')
                      .split(',')
                      .filter(f => f !== i.value)
                      ?.join(',')
                  : undefined,
            },
          };
        },
        () => {
          history.push(
            createResourceLocatorString(
              'SearchPage',
              routeConfiguration(),
              {},
              this.state.currentQueryParams
            )
          );
        }
      );
    };

    // console.log(
    //   1975,
    //   this.state.showClearAll,
    //   this.state.subCategoriesOpenMobile,
    //   this.state.subCategoriesOpen
    // );

    //accommodation feature
    const accommodationFeaturesFilterOptions =
      accommodationFeaturesFilter.config.options;

    const selectedAccommodationFeatures =
      this.state.currentQueryParams.pub_accommodationFeatures?.split(',') ?? [];

    const removeAccommodationFeatures = i => {
      const { history } = this.props;
      this.setState(
        state => {
          return {
            currentQueryParams: {
              ...state.currentQueryParams,
              pub_accommodationFeatures:
                state.currentQueryParams.pub_accommodationFeatures?.split(',')
                  .length > 1
                  ? state.currentQueryParams.pub_accommodationFeatures
                      ?.replace('has_any:', '')
                      .split(',')
                      .filter(f => f !== i.value)
                      ?.join(',')
                  : undefined,
            },
          };
        },
        () => {
          history.push(
            createResourceLocatorString(
              'SearchPage',
              routeConfiguration(),
              {},
              this.state.currentQueryParams
            )
          );
        }
      );
    };
    // const isMobileLayout = viewport.width < MAX_MOBILE_SCREEN_WIDTH;

    // console.log(
    //   1910,
    //   // this.state.accordionFlag,
    //   // this.state.accordionValueOld,
    //   // this.state.accordionValueNew,
    //   this.state.priceOpen
    // );

    return (
      <div className={classes}>
        <div className={css.searchPageContent}>
          <div
            className={
              this.state.categoriesMobileOpen
                ? `${css.filterContainer} ${css.filteCategorysecContainer}`
                : css.filterContainer
            }
            // style={
            //   typeof window !== 'undefined' && window.innerWidth < 768
            //     ? { top: this.state.mobileTopPosition }
            //     : {}
            // }
          >
            <MediaQuery maxWidth={767}>
              <div className={css.mobiletopfiltab}>
                <div
                  className={
                    this.state.categoriesMobileOpen
                      ? `${css.searchfiltertab1} ${css.activatedfltrTab}`
                      : css.searchfiltertab1
                  }
                  onClick={() => {
                    this.setState(state => {
                      return {
                        categoriesOpen: !state.categoriesMobileOpen
                          ? true
                          : false,
                        categoriesMobileOpen: !state.categoriesMobileOpen,
                        filterMobileOpen: state.filterMobileOpen
                          ? !state.filterMobileOpen
                          : state.filterMobileOpen,
                      };
                    });
                  }}
                >
                  {/* {this.state.subCategoriesOpenMobile === '' ||
                this.state.subCategoriesOpen === ''
                  ? 'All Categories'
                  : this.state.subCategoriesOpenMobile} */}
                  Categories
                  {this.state.categoriesMobileOpen ? (
                    <ArrowUp className={css.showHideBtn} />
                  ) : (
                    <ArrowDown className={css.showHideBtn} />
                  )}
                </div>
                <div
                  className={
                    this.state.filterMobileOpen
                      ? `${css.searchfiltertab2} ${css.activatedfltrTab}`
                      : css.searchfiltertab2
                  }
                  onClick={() => {
                    this.setState(state => {
                      return {
                        // priceOpen: !state.filterMobileOpen ? true : false,
                        // priceOpen: !state.priceOpen ? true : state.priceOpen,
                        priceOpen: true,
                        filterMobileOpen: !state.filterMobileOpen,
                        categoriesMobileOpen: state.categoriesMobileOpen
                          ? !state.categoriesMobileOpen
                          : state.categoriesMobileOpen,
                      };
                    });
                  }}
                >
                  <FilterIcon />
                </div>
              </div>
            </MediaQuery>
            {primaryFilters.map(f => {
              const config = {
                max: f.config.max,
                min: f.config.min,
                step: f.config.step,
              };

              return (
                <div key={f.id} className={css.optionWrapper}>
                  {f.id === 'productCategory' ? (
                    <div
                      className={
                        this.state.categoriesMobileOpen
                          ? `${css.filterInnerContainer} ${css.activated}`
                          : css.filterInnerContainer
                      }
                    >
                      <div
                        className={css.optionHeadingBtnDiv}
                        onClick={() => {
                          this.setState(state => {
                            return {
                              categoriesOpen: !state.categoriesOpen,
                              categoriesMobileOpen: state.categoriesOpen
                                ? !state.categoriesMobileOpen
                                : state.categoriesMobileOpen,
                            };
                          });
                        }}
                      >
                        <span className={css.fliterLabel}>
                          {' '}
                          All Categories{' '}
                        </span>
                        {this.state.categoriesOpen ? (
                          <ArrowUp className={css.showHideBtn} />
                        ) : (
                          <ArrowDown className={css.showHideBtn} />
                        )}
                      </div>
                      <span className={css.hDivider} />
                      {this.state.categoriesOpen ? (
                        <div className={css.optionContainer}>
                          {f.config.options.map(o => (
                            <div className={css.options} key={o.value}>
                              <div
                                className={classNames(css.catLabelDiv, {
                                  [css.activeCatLabel]:
                                    this.state.currentQueryParams[
                                      'pub_productCategory'
                                    ] === o.value ||
                                    searchedparam?.pub_productCategory===o.value,
                                })}
                                onClick={() => {
                                  // console.log(232323, o);
                                  const isBagCategory =
                                    o.value === 'bagAndPurse';
                                  const newParams = {
                                    ...this.state.currentQueryParams,
                                    pub_productCategory: o.value,
                                    pub_productSubCategory:
                                      this.state.currentQueryParams[
                                        'pub_productCategory'
                                      ] !== o.value
                                        ? undefined
                                        : this.state.currentQueryParams[
                                            'pub_productSubCategory'
                                          ],
                                    pub_clothingSize:
                                      this.state.currentQueryParams[
                                        'pub_productCategory'
                                      ] !== o.value
                                        ? undefined
                                        : this.state.currentQueryParams[
                                            'pub_clothingSize'
                                          ],
                                    pub_dinningOptions:
                                      this.state.currentQueryParams[
                                        'pub_productCategory'
                                      ] !== o.value
                                        ? undefined
                                        : this.state.currentQueryParams[
                                            'pub_dinningOptions'
                                          ],
                                    pub_bedroomCount:
                                      this.state.currentQueryParams[
                                        'pub_productCategory'
                                      ] !== o.value
                                        ? undefined
                                        : this.state.currentQueryParams[
                                            'pub_bedroomCount'
                                          ],
                                    pub_bathroomCount:
                                      this.state.currentQueryParams[
                                        'pub_productCategory'
                                      ] !== o.value
                                        ? undefined
                                        : this.state.currentQueryParams[
                                            'pub_bathroomCount'
                                          ],
                                  };
                                  const noSubCategories = categoriesWithSubCategories.includes(
                                    o.value
                                  );

                                  // if (!noSubCategories) {
                                  this.setState(state => {
                                    return {
                                      categoriesMobileOpen: state.categoriesOpen
                                        ? !state.categoriesOpen
                                        : state.categoriesOpen,
                                    };
                                  });
                                  // }

                                  // this.setState({
                                  //   categoriesMobileOpen: false,
                                  // });

                                  // For Accordion manipulation partial fix
                                  //  {
                                  //    let flagxx = true;

                                  //    if (
                                  //      this.state
                                  //        .accordionValueNew ===
                                  //        null &&
                                  //      this.state
                                  //        .accordionValueOld ===
                                  //        null
                                  //    ) {
                                  //      this.setState(() => {
                                  //        return {
                                  //          accordionValueOld:
                                  //            o.value,
                                  //          accordionValueNew:
                                  //            o.value,
                                  //        };
                                  //      });
                                  //      flagxx = false;
                                  //    } else {
                                  //      console.log(
                                  //        119100,
                                  //        this.state
                                  //          .accordionValueOld,
                                  //        this.state
                                  //          .accordionValueNew,
                                  //        o.value
                                  //      );
                                  //      this.setState(() => {
                                  //        return {
                                  //          accordionValueOld: this
                                  //            .state
                                  //            .accordionValueNew,
                                  //          accordionValueNew:
                                  //            o.value,
                                  //        };
                                  //      });
                                  //      flagxx = true;
                                  //    }

                                  //    console.log(
                                  //      11910,
                                  //      this.state
                                  //        .accordionValueNew,
                                  //      this.state
                                  //        .accordionValueOld,
                                  //      flagxx,
                                  //      o.value
                                  //    );
                                  //    if (flagxx) {
                                  //      if (
                                  //        // flagxx &&
                                  //        this.state
                                  //          .accordionValueNew ===
                                  //        this.state
                                  //          .accordionValueOld
                                  //      ) {
                                  //        this.setState(() => {
                                  //          return {
                                  //            accordionFlag: !this
                                  //              .state
                                  //              .accordionFlag,
                                  //            // accordionValueOld: this.state
                                  //            // .accordionValueNew,
                                  //            // accordionValueNew: o.value,
                                  //          };
                                  //        });
                                  //      } else {
                                  //        this.setState(() => {
                                  //          return {
                                  //            accordionFlag: true,
                                  //            accordionValueOld: this
                                  //              .state
                                  //              .accordionValueNew,
                                  //            accordionValueNew:
                                  //              o.value,
                                  //          };
                                  //        });
                                  //      }
                                  //    }
                                  //    console.log(
                                  //      11910,
                                  //      this.state
                                  //        .accordionValueNew,
                                  //      this.state
                                  //        .accordionValueOld,
                                  //      flagxx,
                                  //      o.value,
                                  //      'after'
                                  //    );

                                  //    console.log(19100, flagxx);
                                  //  }

                                  // For Accordion manipulation fixed
                                  // {
                                  //   console.log(
                                  //     232,
                                  //     this.state.accordionValueOld,
                                  //     this.state.accordionValueNew
                                  //   );
                                  // }
                                  {
                                    if (
                                      this.state.accordionValueNew === null &&
                                      this.state.accordionValueOld === null
                                    ) {
                                      this.setState(() => {
                                        return {
                                          accordionFlag: true,
                                          accordionValueOld: o.value,
                                          accordionValueNew: o.value,
                                        };
                                      });
                                    } else {
                                      // console.log(
                                      //   119100,
                                      //   this.state.accordionValueOld,
                                      //   this.state.accordionValueNew,
                                      //   o.value
                                      // );
                                      this.setState(
                                        {
                                          accordionValueOld: this.state
                                            .accordionValueNew,
                                          accordionValueNew: o.value,
                                        },
                                        () => {
                                          if (
                                            this.state.accordionValueNew ===
                                            this.state.accordionValueOld
                                          ) {
                                            this.setState(() => {
                                              return {
                                                accordionFlag: !this.state
                                                  .accordionFlag,
                                              };
                                            });
                                          } else {
                                            this.setState(() => {
                                              return {
                                                accordionFlag: true,
                                                accordionValueOld: this.state
                                                  .accordionValueNew,
                                                accordionValueNew: o.value,
                                              };
                                            });
                                          }
                                        }
                                      );
                                    }

                                    // console.log(
                                    //   11910,
                                    //   this.state.accordionValueNew,
                                    //   this.state.accordionValueOld,
                                    //   // flagxx,
                                    //   o.value
                                    // );

                                    // console.log(
                                    //   11910,
                                    //   this.state.accordionValueNew,
                                    //   this.state.accordionValueOld,
                                    //   // flagxx,
                                    //   o.value,
                                    //   'after'
                                    // );

                                    // console.log(19100, flagxx);
                                  }
                                  const combinedParams = isBagCategory
                                    ? {
                                        ...this.state.currentQueryParams,
                                        keywords: 'bag',
                                      }
                                    : newParams;
                                  this.setState(
                                    state => {
                                      return {
                                        subCategoriesOpen: o.value,
                                        // subCategoriesOpenMobile: o.label,
                                        currentQueryParams: newParams,
                                        // currentQueryParams: combinedParams,
                                      };
                                    },
                                    () => {
                                      this.props.history.push(
                                        createResourceLocatorString(
                                          'SearchPage',
                                          routeConfiguration(),
                                          {},
                                          newParams
                                          // combinedParams
                                        )
                                      );
                                    }
                                  );
                                }}
                              >
                                {
                                  <span className={css.catLabel}>
                                    {o.label}
                                  </span>
                                }
                                {categoriesWithSubCategories.includes(
                                  o.value
                                ) ? (
                                  <div className={css.arrowDiv}>
                                    {this.state.subCategoriesOpen === o.value &&
                                    this.state.accordionFlag ? (
                                      <ArrowUp
                                        className={css.showHideBtn}
                                        onClick={() => {
                                          this.setState(state => {
                                            return {
                                              subCategoriesOpen: o.value,
                                            };
                                          });
                                        }}
                                      />
                                    ) : (
                                      <ArrowDown
                                        className={css.showHideBtn}
                                        onClick={() => {
                                          this.setState(state => {
                                            return {
                                              subCategoriesOpen: o.value,
                                            };
                                          });
                                        }}
                                      />
                                    )}
                                  </div>
                                ) : null}
                              </div>

                              <div>
                                {this.state.accordionFlag ? (
                                  <>
                                    {(this.state.subCategoriesOpen ===
                                      o.value ||
                                      this.state.currentQueryParams[
                                        'pub_productCategory'
                                      ] === 'foodDrinks') &&
                                    o.value === 'foodDrinks' ? (
                                      <div className={css.subCategoryTray}>
                                        {primaryFilters
                                          .find(
                                            f =>
                                              f.id === 'foodDrinksSubCategory'
                                          )
                                          .config?.options.map(i => {
                                            return (
                                              <div
                                                key={`${i.key}`}
                                                className={classNames(
                                                  css.subCatLabelDiv,
                                                  {
                                                    [css.activeCatLabel]:
                                                      this.state
                                                        .currentQueryParams[
                                                        'pub_productSubCategory'
                                                      ] === i.value,
                                                  }
                                                )}
                                                onClick={() => {
                                                  const latestParams = {
                                                    ...this.state
                                                      .currentQueryParams,
                                                    pub_productSubCategory:
                                                      i.value,
                                                  };

                                                  this.setState(state => {
                                                    return {
                                                      currentQueryParams: latestParams,
                                                      categoriesMobileOpen: state.categoriesOpen
                                                        ? !state.categoriesOpen
                                                        : state.categoriesOpen,
                                                    };
                                                  });
                                                  this.props.history.push(
                                                    createResourceLocatorString(
                                                      'SearchPage',
                                                      routeConfiguration(),
                                                      {},
                                                      latestParams
                                                    )
                                                  );
                                                }}
                                              >
                                                <span
                                                  className={css.subCatLabel}
                                                >
                                                  {i.label}
                                                </span>
                                              </div>
                                            );
                                          })}
                                      </div>
                                    ) : (this.state.subCategoriesOpen ===
                                        o.value ||
                                        this.state.currentQueryParams[
                                          'pub_productCategory'
                                        ] === 'traditional') &&
                                      o.value === 'traditional' ? (
                                      <div className={css.subCategoryTray}>
                                        {primaryFilters
                                          .find(
                                            f =>
                                              f.id === 'traditionalSubCategory'
                                          )
                                          .config?.options.map(i => {
                                            return (
                                              <div
                                                className={classNames(
                                                  css.subCatLabelDiv,
                                                  {
                                                    [css.activeCatLabel]:
                                                      this.state
                                                        .currentQueryParams[
                                                        'pub_productSubCategory'
                                                      ] === i.value,
                                                  }
                                                )}
                                                onClick={() => {
                                                  const latestParams = {
                                                    ...this.state
                                                      .currentQueryParams,
                                                    pub_productSubCategory:
                                                      i.value,
                                                  };

                                                  this.setState(state => {
                                                    return {
                                                      currentQueryParams: latestParams,
                                                      categoriesMobileOpen: state.categoriesOpen
                                                        ? !state.categoriesOpen
                                                        : state.categoriesOpen,
                                                    };
                                                  });
                                                  this.props.history.push(
                                                    createResourceLocatorString(
                                                      'SearchPage',
                                                      routeConfiguration(),
                                                      {},
                                                      latestParams
                                                    )
                                                  );
                                                }}
                                              >
                                                <span
                                                  className={css.subCatLabel}
                                                >
                                                  {i.label}
                                                </span>
                                              </div>
                                            );
                                          })}
                                      </div>
                                    ) : (this.state.subCategoriesOpen ===
                                        o.value ||
                                        this.state.currentQueryParams[
                                          'pub_productCategory'
                                        ] === 'art') &&
                                      o.value === 'art' ? (
                                      <div className={css.subCategoryTray}>
                                        {primaryFilters
                                          .find(f => f.id === 'artSubCategory')
                                          .config?.options.map(i => {
                                            return (
                                              <div
                                                className={classNames(
                                                  css.subCatLabelDiv,
                                                  {
                                                    [css.activeCatLabel]:
                                                      this.state
                                                        .currentQueryParams[
                                                        'pub_productSubCategory'
                                                      ] === i.value,
                                                  }
                                                )}
                                                onClick={() => {
                                                  const latestParams = {
                                                    ...this.state
                                                      .currentQueryParams,
                                                    pub_productSubCategory:
                                                      i.value,
                                                  };

                                                  this.setState(state => {
                                                    return {
                                                      currentQueryParams: latestParams,
                                                      categoriesMobileOpen: state.categoriesOpen
                                                        ? !state.categoriesOpen
                                                        : state.categoriesOpen,
                                                    };
                                                  });
                                                  this.props.history.push(
                                                    createResourceLocatorString(
                                                      'SearchPage',
                                                      routeConfiguration(),
                                                      {},
                                                      latestParams
                                                    )
                                                  );
                                                }}
                                              >
                                                <span
                                                  className={css.subCatLabel}
                                                >
                                                  {i.label}
                                                </span>
                                              </div>
                                            );
                                          })}
                                      </div>
                                    ) : (this.state.subCategoriesOpen ===
                                        o.value ||
                                        this.state.currentQueryParams[
                                          'pub_productCategory'
                                        ] === 'clothing') &&
                                      o.value === 'clothing' ? (
                                      <div className={css.subCategoryTray}>
                                        {primaryFilters
                                          .find(
                                            f => f.id === 'clothingSubCategory'
                                          )
                                          .config?.options.map(
                                            (i, optionIndx) => {
                                              return (
                                                <div
                                                  className={classNames(
                                                    css.subCatLabelDiv,
                                                    {
                                                      [css.activeCatLabel]:
                                                        this.state
                                                          .currentQueryParams[
                                                          'pub_productSubCategory'
                                                        ] === i.value,
                                                    }
                                                  )}
                                                  onClick={() => {
                                                    const latestParams = {
                                                      ...this.state
                                                        .currentQueryParams,
                                                      pub_productSubCategory:
                                                        i.value,
                                                    };

                                                    this.setState(state => {
                                                      return {
                                                        currentQueryParams: latestParams,
                                                        categoriesMobileOpen: state.categoriesOpen
                                                          ? !state.categoriesOpen
                                                          : state.categoriesOpen,
                                                      };
                                                    });
                                                    this.props.history.push(
                                                      createResourceLocatorString(
                                                        'SearchPage',
                                                        routeConfiguration(),
                                                        {},
                                                        latestParams
                                                      )
                                                    );
                                                  }}
                                                  key={optionIndx}
                                                >
                                                  <span
                                                    className={css.subCatLabel}
                                                  >
                                                    {i.label}
                                                  </span>
                                                </div>
                                              );
                                            }
                                          )}
                                      </div>
                                    ) : (this.state.subCategoriesOpen ===
                                        o.value ||
                                        this.state.currentQueryParams[
                                          'pub_productCategory'
                                        ] === 'jewellery') &&
                                      o.value === 'jewellery' ? (
                                      <div className={css.subCategoryTray}>
                                        {primaryFilters
                                          .find(
                                            f => f.id === 'jewellerySubCategory'
                                          )
                                          .config?.options.map(i => {
                                            return (
                                              <div
                                                className={classNames(
                                                  css.subCatLabelDiv,
                                                  {
                                                    [css.activeCatLabel]:
                                                      this.state
                                                        .currentQueryParams[
                                                        'pub_productSubCategory'
                                                      ] === i.value,
                                                  }
                                                )}
                                                onClick={() => {
                                                  const latestParams = {
                                                    ...this.state
                                                      .currentQueryParams,
                                                    pub_productSubCategory:
                                                      i.value,
                                                  };

                                                  this.setState(state => {
                                                    return {
                                                      currentQueryParams: latestParams,
                                                      categoriesMobileOpen: state.categoriesOpen
                                                        ? !state.categoriesOpen
                                                        : state.categoriesOpen,
                                                    };
                                                  });
                                                  this.props.history.push(
                                                    createResourceLocatorString(
                                                      'SearchPage',
                                                      routeConfiguration(),
                                                      {},
                                                      latestParams
                                                    )
                                                  );
                                                }}
                                              >
                                                <span
                                                  className={css.subCatLabel}
                                                >
                                                  {i.label}
                                                </span>
                                              </div>
                                            );
                                          })}
                                      </div>
                                    ) : (this.state.subCategoriesOpen ===
                                        o.value ||
                                        this.state.currentQueryParams[
                                          'pub_productCategory'
                                        ] === 'beauty') &&
                                      o.value === 'beauty' ? (
                                      <div className={css.subCategoryTray}>
                                        {primaryFilters
                                          .find(
                                            f => f.id === 'beautySubCategory'
                                          )
                                          .config?.options.map(i => {
                                            return (
                                              <div
                                                className={classNames(
                                                  css.subCatLabelDiv,
                                                  {
                                                    [css.activeCatLabel]:
                                                      this.state
                                                        .currentQueryParams[
                                                        'pub_productSubCategory'
                                                      ] === i.value,
                                                  }
                                                )}
                                              >
                                                <span
                                                  className={css.subCatLabel}
                                                  onClick={() => {
                                                    const latestParams = {
                                                      ...this.state
                                                        .currentQueryParams,
                                                      pub_productSubCategory:
                                                        i.value,
                                                    };

                                                    this.setState(state => {
                                                      return {
                                                        currentQueryParams: latestParams,
                                                        categoriesMobileOpen: state.categoriesOpen
                                                          ? !state.categoriesOpen
                                                          : state.categoriesOpen,
                                                      };
                                                    });
                                                    this.props.history.push(
                                                      createResourceLocatorString(
                                                        'SearchPage',
                                                        routeConfiguration(),
                                                        {},
                                                        latestParams
                                                      )
                                                    );
                                                  }}
                                                >
                                                  {i.label}
                                                </span>
                                              </div>
                                            );
                                          })}
                                      </div>
                                    ) : (this.state.subCategoriesOpen ===
                                        o.value ||
                                        this.state.currentQueryParams[
                                          'pub_productCategory'
                                        ] === 'books') &&
                                      o.value === 'books' ? (
                                      <div className={css.subCategoryTray}>
                                        {primaryFilters
                                          .find(
                                            f => f.id === 'booksSubCategory'
                                          )
                                          .config?.options.map(i => {
                                            return (
                                              <div
                                                className={classNames(
                                                  css.subCatLabelDiv,
                                                  {
                                                    [css.activeCatLabel]:
                                                      this.state
                                                        .currentQueryParams[
                                                        'pub_productSubCategory'
                                                      ] === i.value,
                                                  }
                                                )}
                                                onClick={() => {
                                                  const latestParams = {
                                                    ...this.state
                                                      .currentQueryParams,
                                                    pub_productSubCategory:
                                                      i.value,
                                                  };

                                                  this.setState(state => {
                                                    return {
                                                      currentQueryParams: latestParams,
                                                      categoriesMobileOpen: state.categoriesOpen
                                                        ? !state.categoriesOpen
                                                        : state.categoriesOpen,
                                                    };
                                                  });
                                                  this.props.history.push(
                                                    createResourceLocatorString(
                                                      'SearchPage',
                                                      routeConfiguration(),
                                                      {},
                                                      latestParams
                                                    )
                                                  );
                                                }}
                                              >
                                                <span
                                                  className={css.subCatLabel}
                                                >
                                                  {i.label}
                                                </span>
                                              </div>
                                            );
                                          })}
                                      </div>
                                    ) : (this.state.subCategoriesOpen ===
                                        o.value ||
                                        this.state.currentQueryParams[
                                          'pub_productCategory'
                                        ] === 'homeDecor') &&
                                      o.value === 'homeDecor' ? (
                                      <div className={css.subCategoryTray}>
                                        {primaryFilters
                                          .find(
                                            f => f.id === 'homeDecorSubCategory'
                                          )
                                          .config?.options.map(i => {
                                            return (
                                              <div
                                                className={classNames(
                                                  css.subCatLabelDiv,
                                                  {
                                                    [css.activeCatLabel]:
                                                      this.state
                                                        .currentQueryParams[
                                                        'pub_productSubCategory'
                                                      ] === i.value,
                                                  }
                                                )}
                                                onClick={() => {
                                                  const latestParams = {
                                                    ...this.state
                                                      .currentQueryParams,
                                                    pub_productSubCategory:
                                                      i.value,
                                                  };

                                                  this.setState(state => {
                                                    return {
                                                      currentQueryParams: latestParams,
                                                      categoriesMobileOpen: state.categoriesOpen
                                                        ? !state.categoriesOpen
                                                        : state.categoriesOpen,
                                                    };
                                                  });
                                                  this.props.history.push(
                                                    createResourceLocatorString(
                                                      'SearchPage',
                                                      routeConfiguration(),
                                                      {},
                                                      latestParams
                                                    )
                                                  );
                                                }}
                                              >
                                                <span
                                                  className={css.subCatLabel}
                                                >
                                                  {i.label}
                                                </span>
                                              </div>
                                            );
                                          })}
                                      </div>
                                    ) : (this.state.subCategoriesOpen ===
                                        o.value ||
                                        this.state.currentQueryParams[
                                          'pub_productCategory'
                                        ] === 'furniture') &&
                                      o.value === 'furniture' ? (
                                      <div className={css.subCategoryTray}>
                                        {primaryFilters
                                          .find(
                                            f => f.id === 'furnitureSubCategory'
                                          )
                                          .config?.options.map(i => {
                                            return (
                                              <div
                                                className={classNames(
                                                  css.subCatLabelDiv,
                                                  {
                                                    [css.activeCatLabel]:
                                                      this.state
                                                        .currentQueryParams[
                                                        'pub_productSubCategory'
                                                      ] === i.value,
                                                  }
                                                )}
                                                onClick={() => {
                                                  const latestParams = {
                                                    ...this.state
                                                      .currentQueryParams,
                                                    pub_productSubCategory:
                                                      i.value,
                                                  };

                                                  this.setState(state => {
                                                    return {
                                                      currentQueryParams: latestParams,
                                                      categoriesMobileOpen: state.categoriesOpen
                                                        ? !state.categoriesOpen
                                                        : state.categoriesOpen,
                                                    };
                                                  });
                                                  this.props.history.push(
                                                    createResourceLocatorString(
                                                      'SearchPage',
                                                      routeConfiguration(),
                                                      {},
                                                      latestParams
                                                    )
                                                  );
                                                }}
                                              >
                                                <span
                                                  className={css.subCatLabel}
                                                >
                                                  {i.label}
                                                </span>
                                              </div>
                                            );
                                          })}
                                      </div>
                                    ) : (this.state.subCategoriesOpen ===
                                        o.value ||
                                        this.state.currentQueryParams[
                                          'pub_productCategory'
                                        ] === 'household') &&
                                      o.value === 'household' ? (
                                      <div className={css.subCategoryTray}>
                                        {primaryFilters
                                          .find(
                                            f => f.id === 'householdSubCategory'
                                          )
                                          .config?.options.map(i => {
                                            return (
                                              <div
                                                className={classNames(
                                                  css.subCatLabelDiv,
                                                  {
                                                    [css.activeCatLabel]:
                                                      this.state
                                                        .currentQueryParams[
                                                        'pub_productSubCategory'
                                                      ] === i.value,
                                                  }
                                                )}
                                                onClick={() => {
                                                  const latestParams = {
                                                    ...this.state
                                                      .currentQueryParams,
                                                    pub_productSubCategory:
                                                      i.value,
                                                  };

                                                  this.setState(state => {
                                                    return {
                                                      currentQueryParams: latestParams,
                                                      categoriesMobileOpen: this
                                                        .state.categoriesOpen
                                                        ? !state.categoriesOpen
                                                        : state.categoriesOpen,
                                                    };
                                                  });
                                                  this.props.history.push(
                                                    createResourceLocatorString(
                                                      'SearchPage',
                                                      routeConfiguration(),
                                                      {},
                                                      latestParams
                                                    )
                                                  );
                                                }}
                                              >
                                                <span
                                                  className={css.subCatLabel}
                                                >
                                                  {i.label}
                                                </span>
                                              </div>
                                            );
                                          })}
                                      </div>
                                    ) : (this.state.subCategoriesOpen ===
                                        o.value ||
                                        this.state.currentQueryParams[
                                          'pub_productCategory'
                                        ] === 'pets') &&
                                      o.value === 'pets' ? (
                                      <div className={css.subCategoryTray}>
                                        {primaryFilters
                                          .find(f => f.id === 'petsSubCategory')
                                          .config?.options.map(i => {
                                            return (
                                              <div
                                                className={classNames(
                                                  css.subCatLabelDiv,
                                                  {
                                                    [css.activeCatLabel]:
                                                      this.state
                                                        .currentQueryParams[
                                                        'pub_productSubCategory'
                                                      ] === i.value,
                                                  }
                                                )}
                                                onClick={() => {
                                                  const latestParams = {
                                                    ...this.state
                                                      .currentQueryParams,
                                                    pub_productSubCategory:
                                                      i.value,
                                                  };

                                                  this.setState(state => {
                                                    return {
                                                      currentQueryParams: latestParams,
                                                      categoriesMobileOpen: this
                                                        .state.categoriesOpen
                                                        ? !state.categoriesOpen
                                                        : state.categoriesOpen,
                                                    };
                                                  });
                                                  this.props.history.push(
                                                    createResourceLocatorString(
                                                      'SearchPage',
                                                      routeConfiguration(),
                                                      {},
                                                      latestParams
                                                    )
                                                  );
                                                }}
                                              >
                                                <span
                                                  className={css.subCatLabel}
                                                >
                                                  {i.label}
                                                </span>
                                              </div>
                                            );
                                          })}
                                      </div>
                                    ) : (this.state.subCategoriesOpen ===
                                        o.value ||
                                        this.state.currentQueryParams[
                                          'pub_productCategory'
                                        ] === 'businessServices') &&
                                      o.value === 'businessServices' ? (
                                      <div className={css.subCategoryTray}>
                                        {primaryFilters
                                          .find(
                                            f =>
                                              f.id ===
                                              'businessServicesSubCategory'
                                          )
                                          .config?.options.map(i => {
                                            return (
                                              <div
                                                className={classNames(
                                                  css.subCatLabelDiv,
                                                  {
                                                    [css.activeCatLabel]:
                                                      this.state
                                                        .currentQueryParams[
                                                        'pub_productSubCategory'
                                                      ] === i.value,
                                                  }
                                                )}
                                                onClick={() => {
                                                  const latestParams = {
                                                    ...this.state
                                                      .currentQueryParams,
                                                    pub_productSubCategory:
                                                      i.value,
                                                  };

                                                  this.setState(state => {
                                                    return {
                                                      currentQueryParams: latestParams,
                                                      categoriesMobileOpen: state.categoriesOpen
                                                        ? !state.categoriesOpen
                                                        : state.categoriesOpen,
                                                    };
                                                  });
                                                  this.props.history.push(
                                                    createResourceLocatorString(
                                                      'SearchPage',
                                                      routeConfiguration(),
                                                      {},
                                                      latestParams
                                                    )
                                                  );
                                                }}
                                              >
                                                <span
                                                  className={css.subCatLabel}
                                                >
                                                  {i.label}
                                                </span>
                                              </div>
                                            );
                                          })}
                                      </div>
                                    ) : (this.state.subCategoriesOpen ===
                                        o.value ||
                                        this.state.currentQueryParams[
                                          'pub_productCategory'
                                        ] === 'rental') &&
                                      o.value === 'rental' ? (
                                      <div className={css.subCategoryTray}>
                                        {primaryFilters
                                          .find(
                                            f => f.id === 'rentalSubCategory'
                                          )
                                          .config?.options.map(i => {
                                            return (
                                              <div
                                                className={classNames(
                                                  css.subCatLabelDiv,
                                                  {
                                                    [css.activeCatLabel]:
                                                      this.state
                                                        .currentQueryParams[
                                                        'pub_productSubCategory'
                                                      ] === i.value ||
                                                      searchedparam?.pub_productSubCategory ===
                                                        i.value,
                                                  }
                                                )}
                                                onClick={() => {
                                                  const latestParams = {
                                                    ...this.state
                                                      .currentQueryParams,
                                                    pub_productSubCategory:
                                                      i.value,
                                                  };

                                                  this.setState(state => {
                                                    return {
                                                      currentQueryParams: latestParams,
                                                      categoriesMobileOpen: state.categoriesOpen
                                                        ? !state.categoriesOpen
                                                        : state.categoriesOpen,
                                                    };
                                                  });
                                                  this.props.history.push(
                                                    createResourceLocatorString(
                                                      'SearchPage',
                                                      routeConfiguration(),
                                                      {},
                                                      latestParams
                                                    )
                                                  );
                                                }}
                                              >
                                                <span
                                                  className={css.subCatLabel}
                                                >
                                                  {i.label}
                                                </span>
                                              </div>
                                            );
                                          })}
                                      </div>
                                    ) : (this.state.subCategoriesOpen ===
                                        o.value ||
                                        this.state.currentQueryParams[
                                          'pub_productCategory'
                                        ] === 'restaurantsCafe') &&
                                      o.value === 'restaurantsCafe' ? (
                                      <div className={css.subCategoryTray}>
                                        {primaryFilters
                                          .find(
                                            f =>
                                              f.id ===
                                              'restaurantCafeSubCategory'
                                          )
                                          .config?.options.map(i => {
                                            return (
                                              <div
                                                key={`${i.key}`}
                                                className={classNames(
                                                  css.subCatLabelDiv,
                                                  {
                                                    [css.activeCatLabel]:
                                                      this.state
                                                        .currentQueryParams[
                                                        'pub_productSubCategory'
                                                      ] === i.value,
                                                  }
                                                )}
                                                onClick={() => {
                                                  const latestParams = {
                                                    ...this.state
                                                      .currentQueryParams,
                                                    pub_productSubCategory:
                                                      i.value,
                                                  };

                                                  this.setState(state => {
                                                    return {
                                                      currentQueryParams: latestParams,
                                                      categoriesMobileOpen: state.categoriesOpen
                                                        ? !state.categoriesOpen
                                                        : state.categoriesOpen,
                                                    };
                                                  });
                                                  this.props.history.push(
                                                    createResourceLocatorString(
                                                      'SearchPage',
                                                      routeConfiguration(),
                                                      {},
                                                      latestParams
                                                    )
                                                  );
                                                }}
                                              >
                                                <span
                                                  className={css.subCatLabel}
                                                >
                                                  {i.label}
                                                </span>
                                              </div>
                                            );
                                          })}
                                      </div>
                                    ) : null}
                                  </>
                                ) : null}
                              </div>
                            </div>
                          ))}
                        </div>
                      ) : null}
                    </div>
                  ) : f.id === 'price' ? (
                    <div
                      className={
                        this.state.filterMobileOpen
                          ? `${css.filterSectionContainer} ${css.activated}`
                          : css.filterSectionContainer
                      }
                    >
                      <div
                        className={css.optionHeadingBtnDiv}
                        onClick={() => {
                          this.setState(state => {
                            return {
                              priceOpen: !state.priceOpen,
                              // filterMobileOpen: state.priceOpen
                              //   ? !state.filterMobileOpen
                              //   : state.filterMobileOpen,
                            };
                          });
                        }}
                      >
                        <span className={css.fliterLabel}>Price</span>
                        {this.state.priceOpen ? (
                          <ArrowUp className={css.showHideBtn} />
                        ) : (
                          <ArrowDown className={css.showHideBtn} />
                        )}
                      </div>
                      <span className={css.hDivider} />
                      <MediaQuery maxWidth={767}>
                        {this.state.priceOpen && this.state.filterMobileOpen ? (
                          <PriceFilter
                            id={f.id}
                            label={f.label}
                            className={css.PriceFilter}
                            queryParamNames={['price']}
                            initialValues={this.initialValues(
                              f.queryParamNames
                            )}
                            onSubmit={this.getHandleChangedValueFn(true)}
                            contentPlacementOffset={FILTER_DROPDOWN_OFFSET}
                            // isPriceTabActive={this.state.isPriceTabActive}
                            {...config}
                          />
                        ) : null}
                      </MediaQuery>
                      <MediaQuery minWidth={768}>
                        {this.state.priceOpen ? (
                          <PriceFilter
                            id={f.id}
                            label={f.label}
                            className={css.PriceFilter}
                            queryParamNames={['price']}
                            initialValues={this.initialValues(
                              f.queryParamNames
                            )}
                            onSubmit={this.getHandleChangedValueFn(true)}
                            contentPlacementOffset={FILTER_DROPDOWN_OFFSET}
                            // isPriceTabActive={this.state.isPriceTabActive}
                            {...config}
                          />
                        ) : null}
                      </MediaQuery>
                    </div>
                  ) : null}
                </div>
              );
            })}
            <div className={css.mobilefilterremain}>
              <SearchFiltersPrimary
                className={css.searchFiltersPrimary}
                sortByComponent={sortBy('desktop')}
                listingsAreLoaded={listingsAreLoaded}
                resultsCount={totalItems}
                searchInProgress={searchInProgress}
                searchListingsError={searchListingsError}
                {...propsForSecondaryFiltersToggle}
              >
                {this.state.currentQueryParams['pub_productCategory'] ===
                'clothing' ? (
                  <div
                    className={
                      this.state.filterMobileOpen
                        ? `${css.filterSectionContainer} ${css.activated}`
                        : css.filterSectionContainer
                    }
                  >
                    {this.state.filterMobileOpen && (
                      <span className={css.hDivider} />
                    )}
                    <div
                      className={css.optionHeadingBtnDiv}
                      onClick={() => {
                        this.setState(state => {
                          return {
                            clothingSizeOpen: !state.clothingSizeOpen,
                          };
                        });
                      }}
                    >
                      <span className={css.fliterLabel}>Clothing Size</span>
                      {this.state.clothingSizeOpen ? (
                        <ArrowUp className={css.showHideBtn} />
                      ) : (
                        <ArrowDown className={css.showHideBtn} />
                      )}
                    </div>
                    <span className={css.hDivider} />
                    {this.state.clothingSizeOpen ? (
                      <FilterComponent
                        key={`SearchFiltersPrimary.${config.id}`}
                        idPrefix="SearchFiltersPrimary"
                        filterConfig={clothingSizeFilter}
                        urlQueryParams={urlQueryParams}
                        initialValues={this.initialValues}
                        getHandleChangedValueFn={this.getHandleChangedValueFn}
                        showAsPopup={false}
                        contentPlacementOffset={FILTER_DROPDOWN_OFFSET}
                      />
                    ) : null}
                  </div>
                ) : null}
                {this.state.currentQueryParams['pub_productCategory'] ===
                'restaurantsCafe' ? (
                  <div
                    className={
                      this.state.filterMobileOpen
                        ? `${css.filterSectionContainer} ${css.activated}`
                        : css.filterSectionContainer
                    }
                  >
                    {this.state.filterMobileOpen && (
                      <span className={css.hDivider} />
                    )}
                    <div
                      className={css.optionHeadingBtnDiv}
                      onClick={() => {
                        this.setState(state => {
                          return {
                            dinningFilterOpen: !state.dinningFilterOpen,
                          };
                        });
                      }}
                    >
                      <span className={css.fliterLabel}>Dinning Options</span>
                      {this.state.dinningFilterOpen ? (
                        <ArrowUp className={css.showHideBtn} />
                      ) : (
                        <ArrowDown className={css.showHideBtn} />
                      )}
                    </div>
                    <span className={css.hDivider} />
                    {this.state.dinningFilterOpen ? (
                      <FilterComponent
                        key={`SearchFiltersPrimary.${config.id}`}
                        idPrefix="SearchFiltersPrimary"
                        filterConfig={dinningOptionsFilter}
                        urlQueryParams={urlQueryParams}
                        initialValues={this.initialValues}
                        getHandleChangedValueFn={this.getHandleChangedValueFn}
                        showAsPopup={false}
                        contentPlacementOffset={FILTER_DROPDOWN_OFFSET}
                      />
                    ) : null}
                  </div>
                ) : null}

                {this.state.currentQueryParams['pub_productCategory'] ===
                'accommodation' ? (
                  <div
                    className={
                      this.state.filterMobileOpen
                        ? `${css.filterSectionContainer} ${css.activated}`
                        : css.filterSectionContainer
                    }
                  >
                    {this.state.filterMobileOpen && (
                      <span className={css.hDivider} />
                    )}
                    <div
                      className={css.optionHeadingBtnDiv}
                      onClick={() => {
                        this.setState(state => {
                          return {
                            bedroomFilterOpen: !state.bedroomFilterOpen,
                          };
                        });
                      }}
                    >
                      <span className={css.fliterLabel}>Bedroom Numbers</span>
                      {this.state.bedroomFilterOpen ? (
                        <ArrowUp className={css.showHideBtn} />
                      ) : (
                        <ArrowDown className={css.showHideBtn} />
                      )}
                    </div>
                    <span className={css.hDivider} />
                    {this.state.bedroomFilterOpen ? (
                      <FilterComponent
                        key={`SearchFiltersPrimary.${config.id}`}
                        idPrefix="SearchFiltersPrimary"
                        filterConfig={bedroomNumbersFilter}
                        urlQueryParams={urlQueryParams}
                        initialValues={this.initialValues}
                        getHandleChangedValueFn={this.getHandleChangedValueFn}
                        showAsPopup={false}
                        contentPlacementOffset={FILTER_DROPDOWN_OFFSET}
                      />
                    ) : null}
                  </div>
                ) : null}

                {this.state.currentQueryParams['pub_productCategory'] ===
                'accommodation' ? (
                  <div
                    className={
                      this.state.filterMobileOpen
                        ? `${css.filterSectionContainer} ${css.activated}`
                        : css.filterSectionContainer
                    }
                  >
                    {this.state.filterMobileOpen && (
                      <span className={css.hDivider} />
                    )}
                    <div
                      className={css.optionHeadingBtnDiv}
                      onClick={() => {
                        this.setState(state => {
                          return {
                            bathroomFilterOpen: !state.bathroomFilterOpen,
                          };
                        });
                      }}
                    >
                      <span className={css.fliterLabel}>Bathroom Numbers</span>
                      {this.state.bathroomFilterOpen ? (
                        <ArrowUp className={css.showHideBtn} />
                      ) : (
                        <ArrowDown className={css.showHideBtn} />
                      )}
                    </div>
                    <span className={css.hDivider} />
                    {this.state.bathroomFilterOpen ? (
                      <FilterComponent
                        key={`SearchFiltersPrimary.${config.id}`}
                        idPrefix="SearchFiltersPrimary"
                        filterConfig={bathroomNumbersFilter}
                        urlQueryParams={urlQueryParams}
                        initialValues={this.initialValues}
                        getHandleChangedValueFn={this.getHandleChangedValueFn}
                        showAsPopup={false}
                        contentPlacementOffset={FILTER_DROPDOWN_OFFSET}
                      />
                    ) : null}
                  </div>
                ) : null}

                {this.state.currentQueryParams['pub_productCategory'] ===
                'accommodation' ? (
                  <div
                    className={
                      this.state.filterMobileOpen
                        ? `${css.filterSectionContainer} ${css.activated}`
                        : css.filterSectionContainer
                    }
                  >
                    {this.state.filterMobileOpen && (
                      <span className={css.hDivider} />
                    )}
                    <div
                      className={css.optionHeadingBtnDiv}
                      onClick={() => {
                        this.setState(state => {
                          return {
                            accommodationFilterOpen: !state.accommodationFilterOpen,
                          };
                        });
                      }}
                    >
                      <span className={css.fliterLabel}>
                        Accommodation Feature
                      </span>
                      {this.state.accommodationFilterOpen ? (
                        <ArrowUp className={css.showHideBtn} />
                      ) : (
                        <ArrowDown className={css.showHideBtn} />
                      )}
                    </div>
                    <span className={css.hDivider} />
                    {this.state.accommodationFilterOpen ? (
                      <FilterComponent
                        key={`SearchFiltersPrimary.${config.id}`}
                        idPrefix="SearchFiltersPrimary"
                        filterConfig={accommodationFeaturesFilter}
                        urlQueryParams={urlQueryParams}
                        initialValues={this.initialValues}
                        getHandleChangedValueFn={this.getHandleChangedValueFn}
                        showAsPopup={false}
                        contentPlacementOffset={FILTER_DROPDOWN_OFFSET}
                      />
                    ) : null}
                  </div>
                ) : null}
              </SearchFiltersPrimary>
            </div>
          </div>

          <div
            className={classNames(css.listings, {
              [css.newSearchInProgress]: !listingsAreLoaded,
            })}
          >
            {/* {Object.keys(this.state.currentQueryParams).length > 0 ? ( */}
            {Object.keys(searchedparam).length > 0 ? (
              <div className={css.selectedFilters}>
                <SelectedFiltersContainer
                  selectedKeyword={selectedKeyword}
                  selectedLocation={selectedLocation}
                  removeSelectedLocation={removeSelectedLocation}
                  removeSelectedKeyword={removeSelectedKeyword}
                  productCategoryOptions={productCategoryOptions}
                  selectedCategory={selectedCategory}
                  productSubCategoryOptions={productSubCategoryOptions}
                  selectedSubCategory={selectedSubCategory}
                  removeSelectedCategory={removeSelectedCategory}
                  removeSelectedSubCategory={removeSelectedSubCategory}
                  clothingSizeOptions={clothingSizeOptions}
                  selectedClothingSize={selectedClothingSize}
                  removeClothingSize={removeClothingSize}
                  dinningFilterOptions={dinningFilterOptions}
                  selectedDinningOptions={selectedDinningOptions}
                  removeDinningOptions={removeDinningOptions}
                  bedroomNumberOptions={bedroomNumberOptions}
                  selectedBedroomNumbers={selectedBedroomNumbers}
                  removeBedroomNumbers={removeBedroomNumbers}
                  bathroomNumberOptions={bathroomNumberOptions}
                  selectedBathroomNumbers={selectedBathroomNumbers}
                  removeBathroomNumbers={removeBathroomNumbers}
                  accommodationFeaturesFilterOptions={
                    accommodationFeaturesFilterOptions
                  }
                  selectedAccommodationFeatures={selectedAccommodationFeatures}
                  removeAccommodationFeatures={removeAccommodationFeatures}
                  currentQueryParams={this.state.currentQueryParams}
                  handleResetAll={this.resetAll}
                />
                <span className={css.hDivider} />
              </div>
            ) : null}

            <SearchFiltersPrimary
              sortByComponent={sortBy('desktop')}
              listingsAreLoaded={listingsAreLoaded}
              resultsCount={totalItems}
              searchInProgress={searchInProgress}
              isSecondaryFiltersOpen={isSecondaryFiltersOpen}
              selectedSecondaryFiltersCount={selectedSecondaryFiltersCount}
            />
            {searchListingsError ? (
              <div className={css.searchErrorDiv}>
                <h2 className={css.error}>
                  <FormattedMessage id="SearchPage.searchError" />
                </h2>
              </div>
            ) : null}
            {/* {console.log(888, listings, searchInProgress)} */}
            {searchInProgress ? (
              <h1 className={css.pageLoading}>
                <Lottie
                  loop
                  animationData={lottieJson}
                  play
                  style={{ width: 80, height: 80 }}
                />
              </h1>
            ) : listings?.length === 0 && !searchListingsError ? (
              <div className={css.noListingDiv}>
                <NoListSvg className={css.listSvg} />
                <p>
                  Sorry, no Item could be found for your search criteria. Maybe
                  try other search terms?
                </p>
              </div>
            ) : (
              <SearchResultsPanel
                className={css.searchListingsPanel}
                listings={listings}
                pagination={listingsAreLoaded ? pagination : null}
                search={searchParamsForPagination}
                setActiveListing={onActivateListing}
                currentUser={currentUser}
              />
            )}
          </div>
        </div>
      </div>
    );
  }
}

MainPanel.defaultProps = {
  className: null,
  rootClassName: null,
  listings: [],
  resultsCount: 0,
  pagination: null,
  searchParamsForPagination: {},
  filterConfig: config.custom.filters,
  sortConfig: config.custom.sortConfig,
};

MainPanel.propTypes = {
  className: string,
  rootClassName: string,

  urlQueryParams: object.isRequired,
  listings: array,
  searchInProgress: bool.isRequired,
  searchListingsError: propTypes.error,
  searchParamsAreInSync: bool.isRequired,
  onActivateListing: func.isRequired,
  onManageDisableScrolling: func.isRequired,
  onOpenModal: func.isRequired,
  onCloseModal: func.isRequired,
  onMapIconClick: func.isRequired,
  pagination: propTypes.pagination,
  searchParamsForPagination: object,
  showAsModalMaxWidth: number.isRequired,
  filterConfig: propTypes.filterConfig,
  sortConfig: propTypes.sortConfig,

  history: shape({
    push: func.isRequired,
  }).isRequired,
};

export default MainPanel;
//  <MediaQuery maxWidth={767}>
//    <div className={css.mobiletopfiltab}>
//      <div
//        className={
//          this.state.categoriesMobileOpen
//            ? `${css.searchfiltertab1} ${css.activatedfltrTab}`
//            : css.searchfiltertab1
//        }
//        onClick={() => {
//          this.setState(state => {
//            return {
//              categoriesOpen: !state.categoriesMobileOpen ? true : false,
//              categoriesMobileOpen: !state.categoriesMobileOpen,
//              filterMobileOpen: state.filterMobileOpen
//                ? !state.filterMobileOpen
//                : state.filterMobileOpen,
//            };
//          });
//        }}
//      >
//        {this.state.subCategoriesOpenMobile === '' ||
//                 this.state.subCategoriesOpen === ''
//                   ? 'All Categories'
//                   : this.state.subCategoriesOpenMobile}
//        Categories
//        {this.state.categoriesMobileOpen ? (
//          <ArrowUp className={css.showHideBtn} />
//        ) : (
//          <ArrowDown className={css.showHideBtn} />
//        )}
//      </div>
//      <div
//        className={
//          this.state.filterMobileOpen
//            ? `${css.searchfiltertab2} ${css.activatedfltrTab}`
//            : css.searchfiltertab2
//        }
//        onClick={() => {
//          this.setState(state => {
//            return {
//              priceOpen: !state.filterMobileOpen ? true : false,
//              priceOpen: !state.priceOpen ? true : state.priceOpen,
//              priceOpen: true,
//              filterMobileOpen: !state.filterMobileOpen,
//              categoriesMobileOpen: state.categoriesMobileOpen
//                ? !state.categoriesMobileOpen
//                : state.categoriesMobileOpen,
//            };
//          });
//        }}
//      >
//        <FilterIcon />
//      </div>
//    </div>
//  </MediaQuery>;
